.yz_modal_wechat {
  &.ant-modal {
    width: auto !important;
    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;
      .ant-modal-body {
        position: relative;
        padding: .3125rem;
        max-width: 1.9583rem;
        min-width: 1.9583rem;
        .modal_close {
          position: absolute;
          top: .1563rem;
          right: .1563rem;
          cursor: pointer;

          .modal_close_img {
            width: .125rem;
            height: .125rem;
          }
        }
        .modal_header {
          display: flex;
          flex-direction: column;

          .modal_title {
            font-size: .1667rem;
            font-weight: 500;
            color: #000;
            line-height: .2344rem;
          }
          .modal_vice_title {
            margin-top: .0417rem;
            font-size: .0938rem;
            font-weight: 400;
            color: #666;
            line-height: .1302rem;
          }
        }
        .modal_body {
          padding-top: .3125rem;
          display: flex;
          flex-direction: row;
          justify-content: center;

          .modal_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .modal_item_img_box {
              display: flex;
              align-items: center;
              padding: .0521rem;
              border: 2px solid #EAEAEA;
              border-radius: 8px;

              .modal_item_img {
                width: 1.0417rem;
                height: 1.0417rem;
              }

              &>canvas {
                width: 1.0417rem !important;
                height: 1.0417rem !important;
              }
            }
            .modal_item_title {
              margin-top: .0833rem;
              font-size: .125rem;
              font-weight: 400;
              color: #000;
              line-height: .1771rem;
            }
          }
          .modal_item_margin_left {
            margin-left: .3125rem;
          }
        }
        .yz_modal_wechat_content {
          padding-top: .125rem;
          font-size: .125rem;
          font-weight: 500;
          color: #171717;
          line-height: .1771rem;
          text-align: center;
          cursor: default;
        }
        .yz_modal_wechat_btn {
          display: flex;
          flex-direction: row;
          margin-top: .1875rem;
          .divider {
            width: .0521rem;
          }
          .btn {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: .2292rem;
            background-color: #0065E4;
            border: 1px solid #0065E4;
            border-radius: 4px;
            cursor: pointer;
            &>span {
              font-size: .0729rem;
              font-weight: 600;
              color: #FFF;
              line-height: .1042rem;
            }
            &.quxiao {
              background-color: #FFF;
              &>span {
                color: #0065E4;
              }
            }
          }
        }
      }
    }
  }
}