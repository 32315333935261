.buoy_box_m {
  position: fixed;
  right: .266666rem;
  bottom: .64rem;
  display: flex;
  flex-direction: column;
  width: 1.333333rem;
  height: 2.986666rem;
  background-color: transparent;
  z-index: 998;

  .btn_m {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.333333rem;
    height: 1.333333rem;
    background-color: #FFF;
    cursor: pointer;

    &>img {
      min-width: .64rem;
      min-height: .64rem;
      width: .64rem;
      height: .64rem;
    }
  }

  .btn_top_m {
    border-radius: 50%;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  }

  .btn_to_top_m {
    margin-top: .32rem;
    border-radius: 50%;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  }
}