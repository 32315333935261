.ant-modal {
  width: auto !important;
  .ant-modal-content {
    width: auto !important;
    border-radius: 8px;
    .ant-modal-body {
      position: relative;
      padding: .3125rem;
      .modal_close {
        position: absolute;
        top: .1563rem;
        right: .1563rem;

        .modal_close_img {
          width: .125rem;
          height: .125rem;
        }
      }
      .modal_header {
        display: flex;
        flex-direction: column;

        .modal_title {
          font-size: .1667rem;
          font-weight: 500;
          color: #000;
          line-height: .2344rem;
        }
        .modal_vice_title {
          margin-top: .0417rem;
          font-size: .0938rem;
          font-weight: 400;
          color: #666;
          line-height: .1302rem;
        }
      }
      .modal_body {
        padding-top: .3125rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .modal_item {
          display: flex;
          flex-direction: column;
          align-items: center;

          .modal_item_img_box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .0521rem;
            border: 2px solid #EAEAEA;
            border-radius: 8px;

            .modal_item_img {
              width: 1.0417rem;
              height: 1.0417rem;
            }
          }
          .modal_item_title {
            margin-top: .0833rem;
            font-size: .125rem;
            font-weight: 400;
            color: #000;
            line-height: .1771rem;
          }
        }
        .modal_item_margin_left {
          margin-left: .3125rem;
        }
      }
    }
  }
}