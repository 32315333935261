.ndh_header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: .4167rem;

  &>.ndh_header_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 6.875rem;
    &>span {
      font-size: .0729rem;
      font-weight: 400;
      color: #333;
      line-height: .0729rem;
      user-select: none;
      cursor: pointer;
      &.active {
        color: #0065E4;
      }
    }
    &>.to_right {
      padding: 0 .0208rem;
      font-size: .0729rem;
      font-weight: 400;
      color: #333;
      line-height: .0729rem;
    }
  }
}