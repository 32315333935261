.personal_web_m {
  display: flex;
  flex-direction: column;
  padding: 0 .4267rem;
  height: 100%;

  &>.personal_web_m_tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: .1667rem;
    overflow: hidden;

    &>.personal_web_m_tabs_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: .4267rem;
      padding: 0 .5067rem;

      &>.personal_web_m_tabs_header_item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .personal_web_m_tabs_header_item_title {
          font-size: .3733rem;
          font-weight: 500;
          color: #999;
          line-height: .5333rem;

          &.item_active {
            color: #0065E4;
          }
        }
      }
    }

    .personal_web_m_tabs_body {
      margin-top: .8rem;
      width: 100%;
      overflow: hidden;

      .personal_web_m_tabs_table {
        margin-top: .1667rem;
        width: 100%;
        overflow-x: auto;
  
        .ant-table-thead>tr>th.ant-table-cell {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          color: #FFF;
          background-color: #0065E4 !important;
  
          &::before {
            opacity: 0 !important;
          }
        }
      }
    }
  }
}