.pd_introduce {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5208rem 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .pd_introduce_title {
    font-size: .25rem;
    font-weight: 500;
    color: #171717;
    line-height: .349rem;
    text-align: center;
  }

  .pd_introduce_box {
    display: flex;
    flex-direction: row;
    margin-top: .4167rem;
    padding-top: .0417rem;
    max-width: 6.875rem;
    min-width: 6.875rem;

    .pd_introduce_left {
      width: 3.3177rem;
      height: 2.9688rem;

      .pd_introduce_img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .pd_introduce_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: .2344rem;
      padding-right: .0365rem;

      .pd_introduce_right_item {
        display: flex;
        flex-direction: column;
        margin-top: .1667rem;
        width: 100%;

        .pd_introduce_right_header {
          display: flex;
          flex-direction: row;
          align-items: center;

          .pd_introduce_right_divider {
            width: 5px;
            height: 16px;
            background-color: #0065E4;
            border-radius: 12px;
          }

          .pd_introduce_right_title {
            margin-left: .0833rem;
            font-size: 24px;
            font-weight: 400;
            color: #171717;
            line-height: 30px;
          }
        }

        .pd_introduce_right_content {
          margin-top: .0521rem;
          margin-left: .1094rem;
          font-size: 14px;
          font-weight: 400;
          color: #666;
          line-height: 30px;
          text-align: justify;
        }
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .2604rem;
        width: .75rem;
        height: .25rem;
        background-color: #0065E4;
        border-radius: 4px;
        cursor: pointer;
        transition: all 300ms;
        &:hover {
          background-color: rgba(0, 101, 228, 0.8);
        }

        span {
          font-size: .0833rem;
          font-weight: 500;
          color: #FFF;
          line-height: .1458rem;
        }
      }
    }
  }
}