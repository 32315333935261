.personal_web {
  display: flex;
  flex-direction: column;

  .personal_web_title {
    font-size: .125rem;
    font-weight: 500;
    color: #171717;
    line-height: .1771rem;
  }

  .personal_web_tabs {
    display: flex;
    flex-direction: column;
    margin-top: .1667rem;
    overflow: hidden;

    .personal_web_tabs_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .personal_web_tabs_header_item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 1.3125rem;
        height: .2083rem;
        cursor: pointer;

        .personal_web_tabs_header_item_title {
          font-size: .0938rem;
          font-weight: 500;
          color: #171717;
          line-height: .1302rem;

          &.item_active {
            color: #0065E4;
          }
        }

        .personal_web_tabs_header_item_underline {
          width: 100%;
          height: 3px;
          background-color: #0065E4;
        }
      }
    }

    .personal_web_tabs_divider {
      margin-top: .0052rem;
      width: 100%;
      height: 2px;
      background-color: #F5F6F7;
    }

    .personal_web_tabs_body {
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow: hidden;

      .personal_web_tabs_table {
        flex: 1;
        margin-top: .1667rem;
  
        .ant-table-thead>tr>th.ant-table-cell {
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          color: #FFF;
          background-color: #0065E4 !important;
  
          &::before {
            opacity: 0 !important;
          }
        }
      }
    }
  }

  .table_ce {
    width: calc(100vw - 5.4375rem);
    overflow-x: auto;
  }
}