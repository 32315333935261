.yz_background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.15625rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .yz_background_box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .yz_background_title {
      font-size: .3021rem;
      font-weight: 500;
      color: #FFF;
      line-height: .4167rem;
    }
    .yz_background_content {
      margin-top: .0833rem;
      font-size: .125rem;
      font-weight: 400;
      color: #FFF;
      line-height: .1771rem;
    }
    .btn_consult {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: .2292rem;
      width: .7917rem;
      height: .25rem;
      background-color: #0065E4;
      border-radius: 4px;
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        background-color: rgba(0, 101, 228, 0.8);
      }

      span {
        font-size: .0833rem;
        font-weight: 500;
        color: #FFF;
        line-height: .1458rem;
      }
    }
  }
}