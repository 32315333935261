.login_section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .login_section_box {
    width: 2.2083rem;
    background-color: #FFF;
    border-radius: 12px;
    box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05),
    0px 6px 16px 0px rgba(0,0,0,0.08),
    0px 3px 6px -4px rgba(0,0,0,0.12);

    .login_section_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .2083rem .2083rem .1563rem;

      .login_section_title {
        font-size: .125rem;
        font-weight: 400;
        color: #999;
        line-height: .1771rem;
        cursor: pointer;
      }

      .login_section_title_active {
        font-weight: 500;
        color: #171717;
      }

      .login_section_divider {
        margin: 0 .0521rem;
        font-size: .0938rem;
        font-weight: 400;
        color: #EAEAEA;
        line-height: .1302rem;
      }
    }

    .login_section_body {
      display: flex;
      flex-direction: column;
      padding: 0 .2083rem .2083rem;

      &>.yz_input {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .0521rem .0833rem;
        border: 0.5px solid #B0B0B0;
        border-radius: 4px;
  
        &>input {
          margin: 0;
          width: 100%;
          height: 100%;
          font-size: .0781rem;
          font-weight: 400;
          color: #171717;
          line-height: .0781rem;
          border: none;
          background-color: transparent;
          outline: none;
  
          &::placeholder {
            font-size: .0781rem;
            font-weight: 400;
            color: #999;
            line-height: .0781rem;
          }
        }

        .btn_code {
          margin-left: .0625rem;
          font-size: .0781rem;
          font-weight: 400;
          color: #0065E4;
          white-space: nowrap;
          line-height: .0781rem;
          user-select: none;
          cursor: pointer;

          &.no_code {
            color: #999;
            cursor: not-allowed;
          }
        }
      }

      &>.btn_other {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .btn_register {
          margin-top: .1354rem;
          font-size: .0781rem;
          font-weight: 400;
          color: #666;
          line-height: .1094rem;
          cursor: pointer;
        }

        .btn_pwd {
          margin-top: .1354rem;
          font-size: .0781rem;
          font-weight: 400;
          color: #666;
          line-height: .1094rem;
          cursor: pointer;
        }
      }

      &>.btn_login {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .2083rem;
        width: 100%;
        height: .2292rem;
        background-color: #0065E4;
        border-radius: 4px;
        font-size: .0729rem;
        font-weight: 600;
        color: #FFF;
        line-height: .1042rem;
        cursor: pointer;
        transition: all 300ms;
        &:hover {
          background-color: rgba(0, 101, 228, 0.8);
        }
      }

      &>.agreement {
        margin-top: .0521rem;

        .agreement_label {
          font-size: .0781rem;
          font-weight: 400;
          color: #999;
          line-height: .1094rem;
        }

        .btn_agreement {
          font-size: .0781rem;
          font-weight: 400;
          color: #333;
          line-height: .1094rem;
          cursor: pointer;
        }
      }

      &>.third_party_title {
        margin-top: .2083rem;
        font-size: .0729rem;
        font-weight: 400;
        color: #999;
        text-align: center;
        line-height: .1042rem;
      }

      &>.btn_third_party_box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: .1042rem;
        cursor: pointer;

        .third_party_img {
          width: .2083rem;
          height: .2083rem;
        }
      }
    }
  }
}