.ndm_main_m {
  flex: 1;
  display: flex;
  flex-direction: column;
  &>.ndm_main_article_m {
    display: flex;
    flex-direction: column;
    &>.ndm_main_article_header_m {
      display: flex;
      flex-direction: column;
      padding: 0 .4267rem;
      &>.ndm_main_article_header_title_m {
        font-size: .5333rem;
        font-weight: 500;
        color: #171717;
        line-height: .64rem;
        text-align: justify;
      }
      &>.ndm_main_article_header_info_m {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: .4267rem;
        padding-bottom: .32rem;
        border-bottom: 1px solid #EAEAEA;
        &>span {
          font-size: .32rem;
          font-weight: 400;
          color: #999;
          line-height: .4533rem;
          &.margin_left {
            margin-left: .8533rem;
          }
        }
      }
      &>.ndm_main_article_header_content_m {
        margin-top: .4267rem;
        padding-bottom: .5333rem;
        width: 100%;
        font-size: .4rem;
        font-weight: 400;
        color: #999;
        line-height: .5867rem;
        text-align: justify;
        border-bottom: 1px solid #EAEAEA;
      }
    }
    &>.ndm_main_article_body_m {
      padding: .5333rem .4267rem;
      font-size: 16px;
    }
    &>.ndm_main_article_footer_m {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 .4267rem;
      width: 100%;
      height: 1.6533rem;
      &>.ndm_main_article_footer_box_m {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: .4rem;
        font-weight: 400;
        color: #999;
        line-height: .56rem;
        white-space: nowrap;
        &::before {
          content: '';
          display: block;
          margin-right: .32rem;
          width: 100%;
          height: 1px;
          background-color: #EAEAEA;
          overflow: hidden;
        }
        &::after {
          content: '';
          display: block;
          margin-left: .32rem;
          width: 100%;
          height: 1px;
          background-color: #EAEAEA;
          overflow: hidden;
        }
      }
    }
  }
  &>.ndm_main_divider {
    width: 100%;
    height: .2133rem;
    background-color: #EAEAEA;
  }
  &>.new_main_recommend_m {
    padding: .96rem .4267rem .64rem;
    &>.new_main_recommend_box_m {
      display: flex;
      flex-direction: column;
      &>.new_main_recommend_title_m {
        padding-left: .2667rem;
        border-left: 4px solid #0065E4;
        font-size: .4267rem;
        font-weight: 500;
        color: #171717;
        line-height: .48rem;
      }
      &>.new_main_recommend_item_m {
        display: flex;
        flex-direction: row;
        margin-top: .8rem;
        &>img {
          max-width: 2.1333rem;
          min-width: 2.1333rem;
          width: 2.1333rem;
          height: 2.1333rem;
          border-radius: 4px;
          object-fit: cover;
        }
        &>.new_main_recommend_item_content_m {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: .4267rem;
          padding: .1067rem 0;
          &>span {
            font-size: .4267rem;
            font-weight: 600;
            color: #171717;
            line-height: .5867rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-align: justify;
            word-break: break-all;
          }
          &>.new_main_recommend_item_info_m {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            &>span {
              font-size: .32rem;
              font-weight: 400;
              color: #999;
              line-height: .4533rem;
            }
          }
        }
      }
    }
  }
}