.header_mobile {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 .4267rem;
  width: 100%;
  min-height: 1.6rem;
  height: 1.6rem;
  background-color: #0065E4;
  z-index: 999;

  &>.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 1.066667rem;
    overflow: hidden;

    .logo_img {
      // width: 2.1867rem;
      height: 1.066667rem;
      object-fit: contain;
    }
  }

  &>.right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .login {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: .64rem;

      .btn_login,
      .btn_register {
        font-size: .3733rem;
        font-weight: 400;
        color: #FFF;
        line-height: .5333rem;
      }
      .btn_username {
        max-width: 1.6533rem;
        font-size: .3733rem;
        font-weight: 400;
        color: #FFF;
        line-height: .5333rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .divider {
        margin: 0 .32rem;
        width: 1px;
        height: .3733rem;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    .btn_search {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: .64rem;
      width: .64rem;
      height: .64rem;

      &>img {
        width: 100%;
        height: 100%;
      }
    }

    .btn_menu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: .64rem;
      height: .64rem;

      &>img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &>.search_box {
    position: absolute;
    left: 0;
    bottom: 2.4533rem;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 2.4533rem;
    background-color: #FFF;
    transition: bottom 300ms;
    opacity: 0.5;
    z-index: 99;

    &.search_active {
      bottom: -2.4533rem;
      opacity: 1;
    }

    &>.search_main {
      display: flex;
      flex-direction: row;
      padding: .64rem .4267rem;
      width: 100%;
      border-radius: 4px;

      &>.search_input {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 1.1733rem;
        background-color: #FAFAFA;
        border: 0.5px solid #ccc;
        border-right: none;
        border-radius: 4px 0 0 4px;

        &>input {
          margin: 0;
          padding: .32rem .4267rem;
          width: 100%;
          height: 100%;
          font-size: .3733rem;
          font-weight: 400;
          color: #171717;
          border: none;
          background-color: transparent;
          outline: none;

          &::placeholder {
            font-size: .3733rem;
            font-weight: 400;
            color: #999;
          }
        }
      }

      &>.btn_search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.3333rem;
        height: 1.1733rem;
        background-color: #0065E4;
        border-radius: 0 4px 4px 0;
        cursor: pointer;

        .btn_search_img {
          width: .5333rem;
          height: .5333rem;
        }
      }
    }
  }

  .search_mask {
    position: fixed;
    left: 0;
    top: 2.4rem;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1;
  }

  &>.menu_box {
    position: absolute;
    right: -100vw;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #FFF;
    transition: right 300ms;

    &.menu_active {
      right: 0;
      opacity: 1;
    }

    &>.header_mobile_m {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 .4267rem;
      width: 100%;
      min-height: 1.6rem;
      height: 1.6rem;
      background-color: #0065E4;

      &>.logo {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 1.066667rem;
        overflow: hidden;

        .logo_img {
          height: 1.066667rem;
          object-fit: contain;
        }
      }

      &>.btn_close {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: .64rem;
        overflow: hidden;

        &>img {
          width: .64rem;
          height: .64rem;
        }
      }
    }

    &>.menu_list_m {
      display: flex;
      flex-direction: column;

      &.menu_border_top {
        border-top: .2133rem solid #FAFAFA;
      }

      &>.menu_item_m {
        padding: .32rem .64rem;
        font-size: .4rem;
        font-weight: 400;
        color: #171717;
        line-height: .56rem;

        // &:hover {
        //   color: #FFF;
        //   background-color: #0065E4;
        // }

        &.menu_item_m_active {
          color: #FFF;
          background-color: #0065E4;
        }
      }
    }
  }
}

.header_mobile_ccupy {
  min-height: 1.6rem;
  height: 1.6rem;
}