.login_wechat_section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .login_wechat_section_box {
    width: 2.2083rem;
    background-color: #FFF;
    border-radius: 12px;
    box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05),
    0px 6px 16px 0px rgba(0,0,0,0.08),
    0px 3px 6px -4px rgba(0,0,0,0.12);

    .login_wechat_section_header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: .2083rem .2083rem .1563rem;

      .login_wechat_section_title {
        font-size: .125rem;
        font-weight: 500;
        color: #171717;
        line-height: .1771rem;
      }

      .login_wechat_section_vice_title {
        margin-top: .0417rem;
        font-size: .0729rem;
        font-weight: 400;
        color: #999;
        line-height: .1042rem;
      }
    }

    .login_wechat_section_body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 .2083rem .2083rem;

      .login_wechat_section_code {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: .2083rem;
        width: 1.0417rem;
        height: 1.0417rem;

        &>canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .login_wechat_btn_to_login {
        margin-top: .2604rem;
        padding: .0313rem .0521rem;
        font-size: .0729rem;
        font-weight: 500;
        color: #0065E4;
        line-height: .1042rem;
        border: 1px solid #0065E4;
        border-radius: 4px;
        cursor: pointer;
        transition: all 300ms;
        &:hover {
          background-color: #0065E4;
          color: #FFF;
        }
      }

      .login_wechat_agreement {
        margin-top: .1042rem;
        margin-bottom: .1563rem;

        .login_wechat_agreement_label {
          font-size: .0781rem;
          font-weight: 400;
          color: #999;
          line-height: .1094rem;
        }

        .login_wechat_btn_agreement {
          font-size: .0781rem;
          font-weight: 400;
          color: #333;
          line-height: .1094rem;
          cursor: pointer;
        }
      }
    }
  }
}