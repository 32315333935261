.personal_section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
  box-sizing: border-box;
  min-width: 6.875rem;
  overflow-x: hidden;
  overflow-y: auto;

  .personal_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 6.875rem;
    
    .personal_box_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: .3646rem;

      .personal_box_header_item {
        font-size: .0781rem;
        font-weight: 400;
        color: #888;
        line-height: .1094rem;
        cursor: pointer;

        &.item_active {
          color: #126DCA;
        }
      }
      .personal_box_header_arrow {
        margin: 0 .0417rem;
        font-size: .0781rem;
        font-weight: 400;
        color: #888;
        line-height: .1094rem;
      }
    }

    .personal_box_main {
      flex: 1;
      display: flex;
      flex-direction: row;
      padding-bottom: .2604rem;

      .personal_box_aside {
        display: flex;
        flex-direction: column;
        margin-right: .125rem;
        min-width: 1.5625rem;
        width: 1.5625rem;
        height: 100%;
        background-color: #FFF;
        overflow: hidden;
        
        .personal_box_aside_header {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: .2083rem .1042rem;
          overflow: hidden;

          .personal_box_aside_header_img {
            max-width: .3125rem;
            min-width: .3125rem;
            width: .3125rem;
            height: .3125rem;
            background-color: #EAEAEA;
            border-radius: 50%;
            overflow: hidden;

            &>img {
              width: 100%;
              height: 100%;
            }
          }

          .personal_box_aside_header_name {
            margin-left: .0521rem;
            font-size: .1042rem;
            font-weight: 600;
            color: #171717;
            line-height: .1458rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .personal_box_aside_main {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-bottom: .2083rem;
          overflow-y: auto;

          .personal_box_aside_main_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: .125rem;
            width: 100%;
            height: .3229rem;
            background-color: #FFF;
            border-left: 5px solid #FFF;
            font-size: .0833rem;
            font-weight: 400;
            color: #171717;
            line-height: .1146rem;
            cursor: pointer;

            &.item_active {
              border-left: 5px solid #0065E4;
              background-color: #ECF5FF;
            }
            &:hover {
              border-left: 5px solid #0065E4;
              background-color: #ECF5FF;
            }
          }
        }
      }

      .personal_box_article {
        flex: 1;
        padding: .2083rem .3125rem;
        background-color: #FFF;
      }
    }
  }
}