.login_header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 .4167rem;
  width: 100%;
  min-height: .4688rem;
  height: .4688rem;
  background-color: #0065E4;
  z-index: 99999;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: .4688rem;
    overflow: hidden;

    .logo_img {
      width: .9583rem;
      height: .3333rem;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .to_home_img {
      display: flex;
      align-items: center;
      width: .125rem;
      height: .125rem;

      &>img {
        width: 100%;
        height: 100%;
      }
    }
    .to_home_title {
      padding: 0 .1042rem 0 .0521rem;
      font-size: 16px;
      color: #FFF;
      line-height: 16px;
      cursor: pointer;
    }
  }
}

.login_header_ccupy {
  min-height: .4688rem;
  height: .4688rem;
}