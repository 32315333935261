.pdim_introduce {
  display: flex;
  flex-direction: column;
  padding: .8533rem .4267rem;
  background-color: #FAFAFA;

  &>.pdim_header {
    display: flex;
    flex-direction: column;
    margin-bottom: .2667rem;

    &>.header_title {
      font-size: .64rem;
      font-weight: 500;
      color: #171717;
      line-height: .9067rem;
    }

    &>.header_content {
      margin-top: .2133rem;
      font-size: .3733rem;
      font-weight: 400;
      color: #666;
      line-height: .5867rem;
      text-align: justify;
    }
  }

  &>.title {
    font-size: .64rem;
    font-weight: 500;
    color: #171717;
    line-height: .9067rem;
  }

  &>img {
    width: 100%;
  }

  &>.list {
    display: flex;
    flex-direction: column;

    &>.item {
      display: flex;
      flex-direction: column;
      margin-top: .5333rem;

      &>.item_header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .item_divider {
          width: .1333rem;
          height: .4267rem;
          background-color: #0065E4;
          border-radius: 12px;
        }

        .item_title {
          margin-left: .32rem;
          font-size: .4533rem;
          font-weight: 400;
          color: #171717;
          line-height: .8rem;
        }
      }

      &>.item_content {
        margin-top: .16rem;
        margin-left: .4533rem;
        font-size: .3733rem;
        font-weight: 400;
        color: #666;
        line-height: .5867rem;
        text-align: justify;
      }
    }
  }
}