.yz_mpty_m {
  display: flex;
  flex-direction: column;
  align-items: center;

  &>.yz_mpty_m_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;
    
    img {
      width: 4rem !important;
      height: 4rem !important;
    }
  
    span {
      font-size: .32rem;
      font-weight: 400;
      color: #ccc;
      line-height: .5867rem;
    }
  }
}