.yz_modal_login {
  &.ant-modal {
    width: auto !important;
    .ant-modal-content {
      width: auto !important;
      border-radius: 6px;
      .ant-modal-body {
        padding: 40px;
        width: 424px;
        cursor: default;
        &>.ymp_modal_login_close {
          position: absolute;
          top: 24px;
          right: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &>img {
            min-width: 24px;
            width: 24px;
            height: 24px;
          }
        }
        &>.ymp_modal_login_header {
          display: flex;
          flex-direction: column;
          padding-bottom: 30px;
          &>.ymp_modal_login_title {
            font-size: 24px;
            font-weight: 500;
            color: #171717;
            line-height: 34px;
          }
          &>.ymp_modal_login_vice_title {
            margin-top: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #999;
            line-height: 20px;
          }
        }
        &>.yz_modal_login_body {
          display: flex;
          flex-direction: column;
          align-items: center;
          &>canvas {
            margin-top: 10px;
            padding: 10px !important;
            width: 200px !important;
            height: 200px !important;
            cursor: pointer;
          }
          &>.yz_modal_login_body_tips {
            font-size: 16px;
            font-weight: 400;
            color: #666;
            line-height: 22px;
          }
          &>.btn_login {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px;
            padding: 6px 10px;
            border: 1px solid #0065E4;
            border-radius: 4px;
            cursor: pointer;
            transition: all 300ms;
            &:hover {
              background-color: #0065E4;
              &>span {
                color: #FFF;
              }
            }
            &>span {
              font-size: 14px;
              font-weight: 500;
              color: #0065E4;
              line-height: 20px;
            }
          }
          &>.agreement {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 20px 0 30px;
            &>span {
              font-size: 15px;
              font-weight: 400;
              color: #999;
              line-height: 21px;
              &.register {
                color: #333;
                cursor: pointer;
              }
            }
          }
          &>.yz_input {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 16px 0 6px;
            padding: 10px 16px;
            width: 100%;
            border: 0.5px solid #B0B0B0;
            border-radius: 4px;
      
            &>input {
              margin: 0;
              width: 100%;
              height: 100%;
              font-size: 15px;
              font-weight: 400;
              color: #171717;
              line-height: 15px;
              border: none;
              background-color: transparent;
              outline: none;
      
              &::placeholder {
                font-size: 15px;
                font-weight: 400;
                color: #999;
                line-height: 15px;
              }
            }
    
            .btn_code {
              margin-left: 12px;
              font-size: 15px;
              font-weight: 400;
              color: #0065E4;
              white-space: nowrap;
              line-height: 15px;
              cursor: pointer;
              
              &.no_code {
                color: #999;
                cursor: not-allowed;
              }
            }
          }
          &>.btn_bind {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            width: 100%;
            height: 44px;
            background-color: #0065E4;
            border-radius: 4px;
            cursor: pointer;
            &>span {
              font-size: 14px;
              font-weight: 600;
              color: #FFF;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}