.news_details_main {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > .news_details_main_box {
    display: flex;
    flex-direction: row;
    padding: 0.2083rem 0;
    max-width: 6.875rem;
    min-width: 6.875rem;
    overflow: hidden;
    & > aside {
      display: flex;
      flex-direction: column;
      min-width: 1.7188rem;
      max-width: 1.7188rem;
      user-select: none;
      & > .news_aside_sort {
        display: flex;
        flex-direction: column;
        background-color: #fafafa;
        border: 1px solid #eaeaea;
        border-radius: 4px;
        & > .news_aside_sort_title {
          display: flex;
          align-items: center;
          // padding: .1042rem 0 .1042rem .1563rem;
          padding-left: 0.1563rem;
          height: 0.3229rem;
          border-bottom: 1px solid #eaeaea;
          font-size: 0.125rem;
          font-weight: 500;
          color: #171717;
          line-height: 0.1771rem;
          cursor: default;
        }
        & > .news_aside_sort_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          // padding: .1042rem 0 .1042rem .1563rem;
          padding-left: 0.1563rem;
          height: 0.3229rem;
          width: 100%;
          border-top: 1px solid #eaeaea;
          cursor: pointer;
          &.no_border {
            border: none;
          }
          &.active {
            background-color: #ecf5ff;
            & > span {
              color: #0065e4;
            }
          }
          &:hover {
            & > span {
              color: #0065e4;
            }
          }
          & > img {
            min-width: 0.1042rem;
            width: 0.1042rem;
            height: 0.1042rem;
          }
          & > span {
            margin-left: 0.0417rem;
            font-size: 0.0833rem;
            font-weight: 400;
            color: #666;
            line-height: 0.1146rem;
          }
        }
      }
      & > .news_aside_recommend {
        display: flex;
        flex-direction: column;
        margin-top: 0.1563rem;
        background-color: #fafafa;
        border: 1px solid #eaeaea;
        border-radius: 4px;
        & > .news_aside_recommend_title {
          display: flex;
          align-items: center;
          // padding: .1042rem 0 .1042rem .1563rem;
          padding-left: 0.1563rem;
          height: 0.3229rem;
          border-bottom: 1px solid #eaeaea;
          font-size: 0.125rem;
          font-weight: 500;
          color: #171717;
          line-height: 0.1771rem;
          cursor: default;
        }
        & > .news_aside_recommend_list {
          display: flex;
          flex-direction: column;
          padding: 0.125rem;
          & > .news_aside_recommend_item {
            display: flex;
            flex-direction: row;
            margin-top: 0.1563rem;
            cursor: pointer;
            &.no_margin_top {
              margin-top: 0;
            }
            &:hover {
              & > .news_aside_recommend_item_content {
                & > span:first-child {
                  // color: #0065E4;
                }
              }
            }
            & > img {
              max-width: 0.4167rem;
              min-width: 0.4167rem;
              width: 0.4167rem;
              height: 0.4167rem;
              border-radius: 4px;
              object-fit: cover;
              overflow: hidden;
            }
            & > .news_aside_recommend_item_content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 0.0833rem;
              padding: 0.0208rem 0;
              & > span:first-child {
                font-size: 0.0833rem;
                font-weight: 600;
                color: #171717;
                line-height: 0.1146rem;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-align: justify;
                word-break: break-all;
              }
              & > span:last-child {
                margin-top: 0.0521rem;
                font-size: 0.0625rem;
                font-weight: 400;
                color: #9c9c9c;
                line-height: 0.0938rem;
              }
            }
          }
        }
      }
    }
    & > article {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 0.4167rem;
      & > .article_header {
        display: flex;
        flex-direction: column;
        padding-bottom: 0.1042rem;
        border-bottom: 1px solid #eaeaea;
        & > .article_header_title {
          font-size: 0.1667rem;
          font-weight: 600;
          color: #171717;
          line-height: 0.2344rem;
          text-align: justify;
        }
        & > .article_header_info {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 0.125rem;
          & > span {
            font-size: 0.0729rem;
            font-weight: 400;
            color: #999;
            line-height: 0.1042rem;
            &.margin_left {
              margin-left: 0.5208rem;
            }
          }
        }
        & > .article_header_content {
          margin-top: 0.125rem;
          font-size: 0.0833rem;
          font-weight: 400;
          color: #999;
          line-height: 0.125rem;
          text-align: justify;
        }
      }
      & > .article_body {
        flex: 1;
        padding: 0.2083rem 0;
      }
      & > .article_footer {
        display: flex;
        align-items: center;
        height: 0.3229rem;
        font-size: 0.0833rem;
        font-weight: 400;
        color: #999;
        line-height: 0.1146rem;
        white-space: nowrap;
        &::before {
          content: "";
          display: block;
          margin-right: 0.0625rem;
          width: 100%;
          height: 1px;
          background-color: #eaeaea;
          overflow: hidden;
        }
        &::after {
          content: "";
          display: block;
          margin-left: 0.0625rem;
          width: 100%;
          height: 1px;
          background-color: #eaeaea;
          overflow: hidden;
        }
      }
    }
  }
}
