.yz_mpty {
  display: flex;
  justify-content: center;
  align-items: center;

  &>.yz_mpty_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .3125rem;
    
    img {
      width: 1.5521rem !important;
      height: 1.1667rem !important;
    }
  
    span {
      font-size: .0833rem;
      font-weight: 400;
      color: #666;
      line-height: .1146rem;
    }
  }
}