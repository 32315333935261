.personal_section_m {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #fff;
  box-sizing: border-box;
  overflow-y: auto;

  & > .personal_box_m {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > .personal_box_m_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0.4267rem;
      height: 1.6267rem;

      & > .personal_box_m_header_item {
        font-size: 0.4rem;
        font-weight: 400;
        color: #888;
        line-height: 0.56rem;
        cursor: pointer;

        &.item_active {
          color: #126dca;
        }
      }

      & > .personal_box_m_header_arrow {
        margin: 0 0.2133rem;
        font-size: 0.4rem;
        font-weight: 400;
        color: #888;
        line-height: 0.56rem;
      }
    }

    & > .personal_box_m_information {
      padding: 0.32rem 0.4267rem 0;
      height: 2.9333rem;

      & > .personal_box_m_information_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;

        & > .avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 50%;
          overflow: hidden;

          & > img {
            height: 100%;
            object-fit: cover;
          }
        }

        & > .title {
          max-width: 100%;
          font-size: 0.5333rem;
          font-weight: 600;
          color: #171717;
          line-height: 0.7467rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    & > .personal_box_m_tabs {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-top: 0.64rem;

      & > .adm-tabs {
        flex: 1;
        display: flex;
        flex-direction: column;
        & > .adm-tabs-header {
          border-bottom: 0.5px solid #eaeaea;

          & > .adm-tabs-tab-list {
            & > .adm-tabs-tab-line {
              height: 0.08rem;
              border-radius: 0;
            }

            & > .adm-tabs-tab-wrapper {
              padding: 0 0.4rem;

              & > .adm-tabs-tab {
                padding: 0.16rem 0 0.24rem;
                font-size: 0.4rem !important;
                color: #171717;
                line-height: 0.56rem;

                &.adm-tabs-tab-active {
                  color: #0065e4;
                }
              }
            }
          }
        }

        & > .adm-tabs-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 0;
        }
      }
    }
  }
}
