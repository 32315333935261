.yz_modal_treaty {
  &.ant-modal {
    width: auto !important;
    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;
      .ant-modal-body {
        position: relative;
        padding: 0;
        .modal_close {
          position: absolute;
          top: .1563rem;
          right: .1563rem;
          cursor: pointer;
  
          .modal_close_img {
            width: .125rem;
            height: .125rem;
          }
        }
        .modal_content {
          padding: .3125rem;
          width: 5.2083333rem;
          height: 3.125rem;
          overflow-y: auto;
        }
      }
    }
  }
}