.yz_modal_product {
  &.ant-modal {
    width: auto !important;
    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;
      .ant-modal-body {
        padding: 24px;
        &>.ymp_modal_header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          &>.ymp_modal_title {
            font-size: 24px;
            font-weight: 500;
            color: #171717;
            line-height: 34px;
          }
          &>.ymp_modal_close {
            display: flex;
            justify-content: center;
            align-items: center;
            &>img {
              min-width: 24px;
              width: 24px;
              height: 24px;
            }
          }
        }
        &>.ymp_modal_body {
          padding-top: 26px;
          &>span {
            font-size: 16px;
            font-weight: 400;
            color: #333;
            line-height: 22px;
          }
        }
        &>.ymp_modal_footer {
          display: flex;
          flex-direction: row;
          margin-top: 36px;
          &>.btn {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 0;
            background-color: #FFF;
            border: 1px solid #0065E4;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 700;
            color: #0065E4;
            line-height: 14px;
            cursor: pointer;
            &.btn_login {
              background-color: #0065E4;
              color: #FFF;
            }
          }
          &>.divider {
            width: 10px;
          }
        }
      }
    }
  }
}