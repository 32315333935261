.ymp_modal_m {
  &.ant-modal {
    width: auto !important;
    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;
      .ant-modal-body {
        padding: .8rem;
        width: 8.2933rem;
        &>.ymp_modal_header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          &>.ymp_modal_title {
            font-size: .48rem;
            font-weight: 500;
            color: #171717;
            line-height: .6667rem;
          }
          &>.ymp_modal_close {
            display: flex;
            justify-content: center;
            align-items: center;
            &>img {
              min-width: .64rem;
              width: .64rem;
              height: .64rem;
            }
          }
        }
        &>.ymp_modal_body {
          padding-top: .6933rem;
          &>span {
            font-size: .3733rem;
            font-weight: 400;
            color: #333;
            line-height: .5333rem;
          }
        }
        &>.ymp_modal_footer {
          display: flex;
          flex-direction: row;
          margin-top: .96rem;
          &>.btn {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .2133rem 0;
            background-color: #FFF;
            border: 1px solid #0065E4;
            border-radius: 4px;
            font-size: .4rem;
            font-weight: 400;
            color: #0065E4;
            line-height: .72rem;
            cursor: pointer;
            &.btn_login {
              background-color: #0065E4;
              color: #FFF;
            }
          }
          &>.divider {
            width: .2667rem;
          }
        }
      }
    }
  }
}