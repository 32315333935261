.treaty {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 6.875rem;
  &>.treaty_pc {
    display: flex;
    flex-direction: column;
    padding: .3125rem 0;
    width: 6.875rem;
  }
  &>.treaty_mobile {
    display: flex;
    flex-direction: column;
    padding: .64rem .4267rem;
    font-size: .4267rem;
  }
}