.yz_modal_treaty_m {
  &.ant-modal {
    width: auto !important;
    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;
      .ant-modal-body {
        position: relative;
        padding: 0;
        &>.modal_close {
          position: absolute;
          top: .64rem;
          right: .64rem;
          .modal_close_img {
            width: .64rem;
            height: .64rem;
          }
        }
        &>.modal_content {
          padding: .8rem;
          width: 8rem;
          height: 8rem;
          overflow-y: auto;
        }
      }
    }
  }
}