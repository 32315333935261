.pd_exhibition {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5208rem 0;
  background-color: #FFF;

  .pd_exhibition_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 6.875rem;

    .pd_exhibition_title {
      font-size: .25rem;
      font-weight: 500;
      color: #171717;
      line-height: .349rem;
    }
  
    .pd_exhibition_vice_title {
      margin-top: .0417rem;
      font-size: 16px;
      font-weight: 400;
      color: #666;
      line-height: 30px;
      text-align: center;
    }
  }

  .pd_exhibition_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: .4167rem;
    // max-width: 6.875rem;

    .pd_exhibition_item {
      margin-left: .9375rem;

      .pd_exhibition_img {
        width: 1.6667rem;
        object-fit: contain;
      }
    }
  }
}