.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 1.6302085rem;
  height: 1.6302085rem;
  background-color: #0065E4;
  z-index: 999;

  .footer_box {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &>.box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-width: 6.875rem;
      .footer_menus {
        display: flex;
        flex-direction: row;
        padding-top: .2083rem;

        .footer_menus_item {
          display: flex;
          flex-direction: column;
          margin-right: .625rem;

          .footer_menus_item_title {
            font-size: .0833rem;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            line-height: .1146rem;
            white-space: nowrap;
          }

          .footer_menus_item_link {
            display: flex;
            flex-direction: row;
            margin-top: .1042rem;
            font-size: .0833rem;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            line-height: .1146rem;
            cursor: pointer;

            &>img {
              width: .1042rem;
              height: .1042rem;
            }

            .footer_menus_item_link_text {
              margin-left: .0521rem;
              font-size: .0833rem;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);
              line-height: .1146rem;
            }
          }
        }

        .footer_margin_right_156 {
          margin-right: .8125rem;
        }
      }

      .footer_qr_code {
        display: flex;
        flex-direction: row;
        align-items: center;

        .footer_qr_code_item {
          display: flex;
          flex-direction: column;
          align-items: center;

          .footer_qr_code_img {
            width: .7292rem;
            height: .7292rem;
            background-color: #fff;
          }

          .footer_qr_code_text {
            margin-top: .0625rem;
            font-size: .1042rem;
            font-weight: 400;
            color: #FFF;
            line-height: .1458rem;
          }
        }

        .margin_left_60px {
          margin-left: .3125rem;
        }
      }
    }
  }

  .footer_bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: .3125rem;
    background-color: #0048A4;

    &>.box {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 6.875rem;
      .footer_bottom_img {
        width: .1042rem;
        height: .1042rem;
      }
  
      & > .footer_bottom_text {
        margin-left: 4px;
        font-size: .0677rem;
        font-weight: 400;
        color: #ccc;
        line-height: .0938rem;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
      }
      & > .footer_divider {
        font-size: .0677rem;
        font-weight: 400;
        color: #ccc;
        line-height: .0938rem;
      }
    }
  }
}