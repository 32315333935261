.personal_edit_pwd {
  display: flex;
  flex-direction: column;

  .personal_edit_pwd_title {
    font-size: .125rem;
    font-weight: 500;
    color: #171717;
    line-height: .1771rem;
  }

  .personal_edit_pwd_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: .1042rem;
    padding: 6px 0 6px 1.2917rem;

    .personal_edit_pwd_row_title {
      width: .3646rem;
      font-size: .0833rem;
      font-weight: 400;
      color: #171717;
      text-align: end;
      line-height: .1146rem;
      white-space: nowrap;
    }

    .personal_edit_pwd_row_img {
      position: relative;
      margin-left: .0833rem;
      width: .5313rem;
      height: .5313rem;
      background-color: #FAFA;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;

      &>img {
        width: 100%;
        height: 100%;
      }

      &>.personal_edit_pwd_row_img_edit {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(1,1,1,0.5);
        font-size: .0729rem;
        font-weight: 400;
        color: #FFF;
        line-height: .1042rem;
      }
    }

    &>.yz_input {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: .0833rem;
      padding: .0521rem .0833rem;
      width: 1.5625rem;
      border: 0.5px solid #B0B0B0;
      border-radius: 4px;

      &>input {
        margin: 0;
        width: 100%;
        height: 100%;
        font-size: .0781rem;
        font-weight: 400;
        color: #171717;
        line-height: .0781rem;
        border: none;
        background-color: transparent;
        outline: none;

        &::placeholder {
          font-size: .0781rem;
          font-weight: 400;
          color: #999;
          line-height: .0781rem;
        }
      }

      &>.wechat {
        width: 100%;
        height: 100%;
        font-size: .0781rem;
        font-weight: 400;
        color: #0065E4;
        line-height: 15px;
        cursor: pointer;
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    margin-top: .2188rem;

    &>.btn_save {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: .25rem;
      background-color: #0065E4;
      border-radius: 4px;
      cursor: pointer;
  
      &>span {
        font-size: .0833rem;
        font-weight: 500;
        color: #FFF;
        line-height: .1406rem;
      }
    }
  }
}