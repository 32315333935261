.yz_background_m {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.32rem .64rem 2rem;
  width: 100%;
  height: 8.8rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &>.yz_background_box {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    // height: 100%;

    &>.yz_background_foot {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>.yz_background_title {
        font-size: .8533rem;
        font-weight: 500;
        color: #FFF;
        line-height: 1.2rem;
      }

      &>.yz_background_content {
        margin-top: .2133rem;
        font-size: .3467rem;
        font-weight: 400;
        color: #FFF;
        line-height: .4267rem;
        text-align: center;
        white-space: pre-wrap;
      }
    }

    &>.btn_consult {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.44rem;
      width: 3.7333rem;
      height: 1.1467rem;
      background-color: #0065E4;
      border-radius: 4px;
      cursor: pointer;

      &>span {
        font-size: .4rem;
        font-weight: 400;
        color: #FFF;
        line-height: .72rem;
      }
    }
  }
}