.buoy_box {
  position: fixed;
  right: .2083rem;
  bottom: .25rem;
  display: flex;
  flex-direction: column;
  width: .2604rem;
  height: .8646rem;
  background-color: transparent;
  z-index: 1001;

  .btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: .2604rem;
    height: .2604rem;
    background-color: #FFF;
    cursor: pointer;

    &>img {
      min-width: .125rem;
      min-height: .125rem;
      width: .125rem;
      height: .125rem;
    }
  }

  .btn_top {
    border-radius: 50% 50% 0 0;
    box-shadow: 4px 0px 15px 4px rgba(0, 0, 0, 0.1);
  }

  .btn_bottom {
    border-radius: 0 0 50% 50%;
    box-shadow: 0px 4px 15px -4px rgba(0, 0, 0, 0.1);
  }

  .btn_to_top {
    margin-top: .0833rem;
    border-radius: 50%;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

    &>img {
      width: .125rem;
      height: .125rem;
    }
  }

  .buoy_box_modal {
    position: absolute;
    top: -0.0469rem;
    // right: calc(100% + .0677rem);
    right: 100%;
    display: flex;
    flex-direction: column;
    padding-right: .0729165rem;
    .buoy_box_modal_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: .0520835rem;
      background-color: #FAFAFA;
      box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);

      &>.buoy_box_code_img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: .7292rem;
        height: .7292rem;
        background-color: #FFF;

        &>img {
          width: 100%;
          height: 100%;
        }
      }

      &>span {
        margin-top: .0417rem;
        font-size: .0833rem;
        font-weight: 400;
        color: #333;
        line-height: .1406rem;
        white-space: nowrap;
      }
    }
  }
}