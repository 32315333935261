.login_footer {
  position: absolute;
  left: 50%;
  bottom: .1667rem;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;

  .login_footer_img {
    width: .1042rem;
    height: .1042rem;
  }

  & > .login_footer_title {
    margin-left: 4px;
    font-size: .0677rem;
    font-weight: 400;
    color: #333;
    line-height: .0938rem;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }
  & > .footer_divider {
    font-size: .0677rem;
    font-weight: 400;
    color: #333;
    line-height: .0938rem;
  }
}