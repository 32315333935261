.header_mobile_m {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 .4267rem;
  width: 100%;
  min-height: 1.6rem;
  height: 1.6rem;
  background-color: #0065E4;

  &>.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 1.066667rem;
    overflow: hidden;

    .logo_img {
      // width: 2.1867rem;
      height: 1.066667rem;
      object-fit: contain;
    }
  }

  &>.btn_close  {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: .64rem;
    overflow: hidden;

    &>img {
      width: .64rem;
      height: .64rem;
    }
  }
}