.personal_userinfo_m {
  display: flex;
  flex-direction: column;
  padding: 0 .4267rem;

  &>.personal_userinfo_m_title {
    margin-top: .8rem;
    font-size: .4533rem;
    font-weight: 500;
    color: #171717;
    line-height: .64rem;
  }

  &>.personal_userinfo_m_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: .4267rem;
    padding: .16rem 0;

    &.start {
      align-items: flex-start;
    }

    &>.personal_userinfo_m_row_title {
      margin-right: .32rem;
      width: 2.08rem;
      font-size: .4rem;
      font-weight: 400;
      color: #171717;
      line-height: .56rem;
      white-space: nowrap;
    }

    .personal_userinfo_m_row_img {
      position: relative;
      width: 2.72rem;
      height: 2.72rem;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;

      &>img {
        width: 100%;
        height: 100%;
      }

      &>.personal_userinfo_m_row_img_edit {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(1,1,1,0.5);
        font-size: .3733rem;
        font-weight: 400;
        color: #FFF;
        line-height: .5333rem;
      }
    }

    &>.yz_input_m {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .2667rem .32rem;
      width: 100%;
      height: 1.0667rem;
      border: 0.5px solid #B0B0B0;
      border-radius: 4px;

      &>input {
        margin: 0;
        width: 100%;
        height: 100%;
        font-size: .3733rem;
        font-weight: 400;
        color: #171717;
        line-height: .5333rem;
        border: none;
        background-color: transparent;
        outline: none;

        &::placeholder {
          font-size: .3733rem;
          font-weight: 400;
          color: #999;
          line-height: .5333rem;
        }
      }

      &>.wechat_m {
        width: 100%;
        height: 100%;
        font-size: .3733rem;
        font-weight: 400;
        color: #0065E4;
        line-height: .5333rem;
        user-select: none;
        cursor: pointer;

        &.yes_wechat_m {
          color: #999;
          cursor: not-allowed;
        }
      }

      &>.btn_code_m {
        margin-left: .32rem;
        font-size: .4rem;
        font-weight: 400;
        color: #0065E4;
        white-space: nowrap;
        line-height: .5333rem;
        user-select: none;
        cursor: pointer;

        &.no_code {
          color: #999;
          cursor: not-allowed;
        }
      }
    }
  }

  .btn_m {
    display: flex;
    justify-content: center;
    margin-top: .8rem;
    margin-bottom: .8533rem;

    &>.btn_save_m {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 5.2533rem;
      height: 1.1467rem;
      background-color: #0065E4;
      border-radius: 4px;
      cursor: pointer;
  
      &>span {
        font-size: .4rem;
        font-weight: 400;
        color: #FFF;
        line-height: .72rem;
      }
    }
  }
}