.search_sort {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  background-color: #FAFAFA;

  .search_sort_label {
    display: flex;
    padding-top: .2083rem;
    max-width: 7.0416666rem;
    min-width: 7.0416666rem;
    font-size: .1042rem;
    font-weight: 400;
    color: #171717;
    line-height: .1458rem;
  }

  .search_sort_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: .2813rem;
    max-width: 7.21875rem;
    min-width: 7.21875rem;
    min-height: calc(100vh - 3.4635rem);

    .search_sort_item {
      display: flex;
      padding: 0 .078125rem .28125rem;
      width: 33.3333%;
      height: max-content;

      .search_sort_item_box {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 2.3854rem;
        background-color: #FFF;
        box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.03);
        border-radius: 8px;
        overflow: hidden;
        transition: all 300ms;
        cursor: pointer;

        &:hover {
          transform: translateY(-6px);
          box-shadow: 0 0 8px 2px rgba(0,0,0,0.2);
        }

        .yz_tag {
          position: absolute;
          top: 0;
          right: 0;
          padding: 8px 12px;
          background-color: #0065E4;
          border-radius: 0 8px 0 8px;
          font-size: .0833rem;
          font-weight: 400;
          color: #FFF;
          line-height: .1146rem;
          &.news_item {
            background-color: #FAAD14;
          }
        }

        .search_sort_img {
          width: 100%;
          // min-height: 1.3542rem;
          height: 1.3542rem;
          border-radius: 8px 8px 0 0;
          object-fit: cover;
        }

        .search_sort_box {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: .1042rem .1042rem .1042rem .1042rem;
          border-top: 1px solid #FAFAFA;

          .search_sort_title {
            font-size: .1042rem;
            font-weight: 500;
            color: #171717;
            line-height: .1458rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-align: justify;
          }

          .search_sort_divider {
            margin-top: .0417rem;
            width: .1563rem;
            height: 4px;
            background-color: #0065E4;
          }

          .search_sort_content {
            margin-top: .0833rem;
            font-size: 14px;
            font-weight: 400;
            color: #999;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-align: justify;
          }
        }
      }
    }
  }

  .search_sort_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: .7292rem;
  }
}