.login_pwd_section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .login_pwd_section_box {
    width: 2.2083rem;
    background-color: #FFF;
    border-radius: 12px;
    box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05),
    0px 6px 16px 0px rgba(0,0,0,0.08),
    0px 3px 6px -4px rgba(0,0,0,0.12);

    .login_pwd_section_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .2083rem .2083rem .1563rem;

      .login_pwd_section_title {
        font-size: .125rem;
        font-weight: 500;
        color: #171717;
        line-height: .1771rem;
      }
    }

    .login_pwd_section_body {
      display: flex;
      flex-direction: column;
      padding: 0 .2083rem .2083rem;

      &>.yz_input {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .0521rem .0833rem;
        border: 0.5px solid #B0B0B0;
        border-radius: 4px;
  
        &>input {
          margin: 0;
          width: 100%;
          height: 100%;
          font-size: .0781rem;
          font-weight: 400;
          color: #171717;
          line-height: .0781rem;
          border: none;
          background-color: transparent;
          outline: none;
  
          &::placeholder {
            font-size: .0781rem;
            font-weight: 400;
            color: #999;
            line-height: .0781rem;
          }
        }

        .btn_code {
          margin-left: .0625rem;
          font-size: .0781rem;
          font-weight: 400;
          color: #0065E4;
          white-space: nowrap;
          line-height: .0781rem;
          cursor: pointer;

          &.no_code {
            color: #999;
            cursor: not-allowed;
          }
        }
      }

      &>.btn_login {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .1875rem;
        width: 100%;
        height: .2292rem;
        background-color: #0065E4;
        border-radius: 4px;
        font-size: .0729rem;
        font-weight: 600;
        color: #FFF;
        line-height: .1042rem;
        cursor: pointer;
        transition: all 300ms;
        &:hover {
          background-color: rgba(0, 101, 228, 0.8);
        }
      }
    }
  }
}