.login_section_m {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y: auto;

  &>.login_section_m_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.0667rem .64rem .8rem;

    &>.login_section_m_title {
      font-size: .64rem;
      font-weight: 400;
      color: #999;
      line-height: .9067rem;
      cursor: pointer;
    }

    &>.login_section_m_title_active {
      font-weight: 500;
      color: #171717;
    }

    &>.login_section_m_divider {
      margin: 0 .2667rem;
      font-size: .48rem;
      font-weight: 400;
      color: #CCC;
      line-height: .6667rem;
    }
  }

  &>.login_section_m_body {
    display: flex;
    flex-direction: column;
    padding: 0 .64rem;

    &>.yz_input_m {
      // flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: .2667rem .4267rem;
      height: 1.0933rem;
      border: 0.5px solid #B0B0B0;
      border-radius: 4px;

      &>input {
        margin: 0;
        width: 100%;
        height: 100%;
        font-size: .4rem;
        font-weight: 400;
        color: #171717;
        line-height: .5333rem;
        border: none;
        background-color: transparent;
        outline: none;

        &::placeholder {
          font-size: .4rem;
          font-weight: 400;
          color: #999;
          line-height: .5333rem;
        }
      }

      &>.btn_code_m {
        margin-left: .32rem;
        font-size: .4rem;
        font-weight: 400;
        color: #0065E4;
        white-space: nowrap;
        line-height: .5333rem;
        user-select: none;
        cursor: pointer;

        &.no_code {
          color: #999;
          cursor: not-allowed;
        }
      }
    }

    &>.btn_other_m {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: .6933rem;

      &>.btn_register_m {
        font-size: .4rem;
        font-weight: 400;
        color: #666;
        line-height: .56rem;
        cursor: pointer;
      }

      &>.btn_pwd_m {
        font-size: .4rem;
        font-weight: 400;
        color: #666;
        line-height: .56rem;
        cursor: pointer;
      }
    }

    &>.btn_login_m {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.0667rem;
      width: 100%;
      height: 1.1733rem;
      background-color: #0065E4;
      border-radius: 4px;
      font-size: .3733rem;
      font-weight: 600;
      color: #FFF;
      line-height: .5333rem;
      cursor: pointer;
    }

    &>.agreement_m {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: .2667rem;

      &>.agreement_label_m {
        font-size: .4rem;
        font-weight: 400;
        color: #999;
        line-height: .56rem;
      }

      &>.btn_agreement_m {
        font-size: .4rem;
        font-weight: 400;
        color: #333;
        line-height: .56rem;
        cursor: pointer;
      }
    }

    &>.third_party_title_m {
      margin-top: 1.0667rem;
      font-size: .3733rem;
      font-weight: 400;
      color: #999;
      text-align: center;
      line-height: .5333rem;
    }

    &>.btn_third_party_box_m {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: .5333rem;
      cursor: pointer;

      &>img {
        width: 1.0667rem;
        height: 1.0667rem;
      }
    }
  }
}