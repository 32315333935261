.yz_modal_wechat_m {
  &.ant-modal {
    width: auto !important;
    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;
      .ant-modal-body {
        position: relative;
        padding: .8rem;
        width: 8.2667rem;
        &>.modal_close {
          position: absolute;
          top: .64rem;
          right: .64rem;
          .modal_close_img {
            width: .64rem;
            height: .64rem;
          }
        }
        &>.modal_header {
          display: flex;
          flex-direction: column;
          &>.modal_title {
            font-size: .48rem;
            font-weight: 500;
            color: #000;
            line-height: .64rem;
          }
          &>.modal_vice_title {
            margin-top: .1067rem;
            font-size: .3733rem;
            font-weight: 400;
            color: #666;
            line-height: .5333rem;
          }
        }
        &>.modal_body {
          padding-top: .8rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          &>.modal_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            &>.modal_item_img_box {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: .32rem;
              border: 1px solid #EAEAEA;
              border-radius: 8px;
              &>canvas {
                width: 4rem !important;
                height: 4rem !important;
              }
            }
            &>.modal_item_title {
              margin-top: .2133rem;
              font-size: .3733rem;
              font-weight: 400;
              color: #000;
              line-height: .48rem;
            }
          }
          &>.modal_item_margin_left {
            margin-left: .8rem;
          }
        }
        .yz_modal_wechat_content {
          padding-top: 1.2267rem;
          font-size: .48rem;
          font-weight: 500;
          color: #171717;
          line-height: .6667rem;
          text-align: center;
          cursor: default;
        }
        .yz_modal_wechat_btn {
          display: flex;
          flex-direction: row;
          margin-top: .96rem;
          .divider {
            width: .2667rem;
          }
          .btn {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1.1733rem;
            background-color: #0065E4;
            border: 1px solid #0065E4;
            border-radius: 4px;
            cursor: pointer;
            &>span {
              font-size: .4rem;
              font-weight: 500;
              color: #FFF;
              line-height: .72rem;
            }
            &.quxiao {
              background-color: #FFF;
              &>span {
                color: #0065E4;
              }
            }
          }
        }
      }
    }
  }
}