.search_sort_m {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;

  &>.title {
    padding: .64rem .4267rem .2133rem;
    font-size: .3733rem;
    font-weight: 400;
    color: #000;
    line-height: .5333rem;
  }

  &>.list {
    display: flex;
    flex-direction: column;
    padding: 0 .4267rem .64rem;

    &>.item {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: .64rem;
      background-color: #FFF;
      border-radius: 2px;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.04);
      overflow: hidden;

      &>.yz_tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: .16rem .2667rem;
        background-color: #0065E4;
        border-radius: 0 0 0 8px;
        font-size: .3733rem;
        font-weight: 400;
        color: #FFF;
        line-height: .5333rem;
        &.news_item {
          background-color: #FAAD14;
        }
      }

      &>img {
        width: 100%;
        max-height: 5.6533rem;
        min-height: 2.6667rem;
        object-fit: cover;
      }

      &>.item_box {
        display: flex;
        flex-direction: column;
        padding: .4267rem .4267rem .56rem;

        &>.item_title {
          font-size: .4267rem;
          font-weight: 500;
          color: #171717;
          line-height: .5867rem;
        }

        &>.item_divider {
          margin-top: .1067rem;
          width: .5333rem;
          height: .08rem;
          background-color: #0065E4;
        }

        &>.item_content {
          margin-top: .4267rem;
          font-size: .3467rem;
          font-weight: 400;
          color: #666;
          line-height: .4rem;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 3;
          // overflow: hidden;
          text-align: justify;
        }
      }
    }
  }

  &>.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.1867rem;
  }
}