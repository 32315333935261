.m_footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0048A4;
  height: 1.8667rem;

  &>.m_content1 {
    display: flex;
    flex-direction: row;
    align-items: center;

    &>img {
      width: .5333rem;
      height: .5333rem;
    }
    &>span {
      margin-left: .1067rem;
      font-size: .3467rem;
      font-weight: 400;
      color: #ccc;
      line-height: .48rem;
    }
  }

  &>.m_content2 {
    font-size: .3467rem;
    font-weight: 400;
    color: #ccc;
    line-height: .48rem;
  }
}