.yz_modal_m {
  &.ant-modal {
    width: auto !important;
    .ant-modal-content {
      width: auto !important;
      border-radius: 8px;
      .ant-modal-body {
        position: relative;
        padding: .8rem;
        &>.modal_close {
          position: absolute;
          top: .4rem;
          right: .4rem;
          .modal_close_img {
            width: .32rem;
            height: .32rem;
          }
        }
        &>.modal_header {
          display: flex;
          flex-direction: column;
          &>.modal_title {
            font-size: .4267rem;
            font-weight: 500;
            color: #000;
            line-height: .6rem;
          }
          &>.modal_vice_title {
            margin-top: .1067rem;
            font-size: .32rem;
            font-weight: 400;
            color: #666;
            line-height: .5333rem;
          }
        }
        &>.modal_body {
          padding-top: .8rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          &>.modal_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            &>a {
              &>.modal_item_img_box {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: .1333rem;
                border: 1px solid #EAEAEA;
                border-radius: 8px;
                &>.modal_item_img {
                  width: 2.6667rem;
                  height: 2.6667rem;
                }
              }
            }
            &>.modal_item_title {
              margin-top: .2133rem;
              font-size: .32rem;
              font-weight: 400;
              color: #000;
              line-height: .4533rem;
            }
          }
          &>.modal_item_margin_left {
            margin-left: .8rem;
          }
        }
      }
    }
  }
}