.login_section_mw {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y: auto;

  &>.login_section_mw_header {
    display: flex;
    flex-direction: column;
    padding: 1.0667rem .64rem .8rem;

    &>.login_section_mw_title {
      font-size: .64rem;
      font-weight: 400;
      color: #999;
      line-height: .9067rem;
      cursor: pointer;
    }

    &>.login_section_mw_title_active {
      font-weight: 500;
      color: #171717;
    }

    &>.login_section_mw_content {
      margin-top: .2133rem;
      font-size: .3733rem;
      font-weight: 400;
      color: #999;
      line-height: .5333rem;
      cursor: pointer;
    }
  }

  &>.login_section_mw_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 .64rem;

    &>.login_wechat_section_code {
      margin-top: .2667rem;

      &>canvas {
        width: 5.3333rem !important;
        height: 5.3333rem !important;
      }
    }

    &>.login_wechat_btn_to_login {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.3333rem;
      width: 2.7733rem;
      height: .8533rem;
      border: 1px solid #0065E4;
      border-radius: 4px;
      font-size: .3733rem;
      color: #0065E4;
      line-height: .5333rem;
    }

    &>.agreement_m {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: .5333rem;

      &>.agreement_label_m {
        font-size: .4rem;
        font-weight: 400;
        color: #999;
        line-height: .56rem;
      }

      &>.btn_agreement_m {
        font-size: .4rem;
        font-weight: 400;
        color: #333;
        line-height: .56rem;
        cursor: pointer;
      }
    }
  }
}